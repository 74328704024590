<template>
     <!-- 对比框start -->
     <div>
          <div class="contrast panelFixed" id="contrast" v-if="contrastFlag">
               <div class="wrap22 flex-col">
                    <div class="mod30 flex-col">
                         <div class="bd38 flex-row">
                              <span class="txt51">对比栏</span>
                              <span class="txt52" @click="contrastFlag = false">隐藏</span>
                         </div>
                         <div class="bd40 flex-row">
                              <div class="layer113 flex_column_center_center" v-for="(item, i) in 4" :key="i">
                                   <div class="outer22 flex_column_center_start">
                                        <div class="mod31 flex_row_center_center">
                                             <div class="box22 flex-col" v-if="contraList[i]">
                                                  <img :src="contraList[i][`${currentType}Image`]">
                                             </div>
                                             <span class="txt54" v-else>{{ i + 1 }}</span>
                                        </div>
                                        <div style="margin-top: 10px;" v-if="contraList[i]">
                                             <div class="word149">{{ contraList[i][`${currentType}Name`] }}</div>
                                             <div class="word149_1">
                                                  {{ contraList[i].specValues }}
                                             </div>
                                        </div>

                                        <span class="word151" v-else>您还可以继续添加</span>
                                   </div>
                                   <span class="info70" @click="contraDel('spec', i, contraList[i])"
                                        v-if="contraList[i]">删除</span>
                              </div>
                              <div class="outer28 flex-col">
                                   <div class="outer29 flex-col" @click="toContrast">
                                        <span class="word152">对比</span>
                                   </div>
                                   <span class="word153" @click="contraDel('all', index)">清空对比栏</span>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>

</template>

<script>
import { reactive, toRefs, ref, defineComponent, onMounted, nextTick, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import eventEmitter from '@/utils/eventEmitter.js'

//由于该组件属于render独立渲染，并不挂载在app下，无法获取useRouter, useRoute的Hook方法
//用此方法引进
import store from '@/store';
import router from '@/router'
export default defineComponent({
     setup() {
          const { proxy } = getCurrentInstance()
          const contrastFlag = ref(false)
          const contraList = ref(store.state.contrastList || [])
          const currentType = ref('')
          const contraDel = (type, index, obj) => {
               if (type == 'spec') {
                    contraList.value.splice(index, 1);
                    (!contraList.value.length) && (currentType.value = '')


               } else {
                    contraList.value = []
                    currentType.value = ''
               }
               console.log(proxy)
               eventEmitter.emit('contraDel', type, obj)

               store.commit('updateContrastList', contraList.value)
          }

          const toContrast = () => {
               if (!contraList.value.length) {
                    ElMessage('请添加对比条目')
                    return
               }
               let list = contraList.value.map(i => i[`${currentType.value}Id`])
               let newWin = router.resolve({
                    path: '/goods/Contrast',
                    query: {
                         ids: encodeURIComponent(list.join(',')),
                         type: currentType.value
                    }
               })
               window.open(newWin.href, '_blank')
          }

          //加入对比
          const contrast = (item) => {
               if (currentType.value) {
                    if (currentType.value != item.type) {
                         ElMessage(`只能对比同类型项`)

                         return {
                              state: false
                         }
                    }
               } else {
                    currentType.value = item.type
               }


               if (contraList.value.length < 4) {
                    let index = contraList.value.findIndex(i => i[`${currentType.value}Id`] == item[`${currentType.value}Id`])
                    if (index < 0) {
                         contraList.value.push(item)
                    } else {
                         let p = {
                              product: '产品',
                              series: '系列'
                         }
                         if (item.type == 'product') ElMessage(`该${p[item.type]}已加入对比`)

                         if (item.type == 'series') contraList.value.splice(index, 1);
                    }
               } else {
                    ElMessage('对比已达到4个')
                    return {
                         state: false
                    }
               }
               store.commit('updateContrastList', contraList.value)

               return {
                    state: true
               }
          }


          router.afterEach(() => {
               contrastFlag.value = false
          })


          onMounted(() => {

          })


          return {
               contraList,
               contrastFlag,
               toContrast,
               contrast,
               contraDel,
               currentType
          }
     },




})
</script>

<style lang="scss">
.panelFixed {
     width: 100%;
     position: fixed;
     bottom: 0;
     left: 0;
}

.contrast_panel {
     height: 290px;
}

.contrast {
     // position: absolute;
     z-index: 999;
     bottom: 0;
     left: 0;
     display: flex;
     justify-content: center;

     .wrap22 {
          height: 288px;
          background: #fff;
          width: 1200px;
          border: 2px solid #cccccc;

          .mod30 {
               .bd38 {
                    padding: 0 20px;
                    align-items: center;
                    justify-content: space-between;
                    height: 54px;
                    border-bottom: 1px solid #cccccc;

                    .txt51 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 16px;
                    }

                    .txt52 {
                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         cursor: pointer;
                    }
               }

               .bd40 {
                    height: 228px;

                    .layer113 {
                         width: 240px;
                         position: relative;
                         border-right: 1px dashed #999999;

                         .info70 {
                              position: absolute;
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              top: 13px;
                              right: 13px;
                              cursor: pointer;
                         }

                         .outer22 {
                              width: 150px;
                              height: 179px;
                              margin-top: 20px;

                              .mod31 {
                                   position: relative;
                                   width: 150px;
                                   height: 150px;
                                   overflow: hidden;
                                   background: #f7f7f7f7;

                                   .box22 {

                                        width: 150px;
                                        height: 150px;
                                        background: #f7f7f7f7;

                                        img {
                                             width: 150px;
                                             height: 150px;
                                        }
                                   }
                              }
                         }
                    }
               }
          }

          .word149 {
               width: 150px;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               margin-top: 15px;
          }

          .word149_1 {
               color: #999;
               margin-top: 10px;
               font-size: 12px;
          }

          .txt54 {

               color: rgba(204, 204, 204, 1);
               font-size: 74px;
               line-height: 120px;
          }

          .word151 {
               color: rgba(153, 153, 153, 1);
               font-size: 14px;
               margin-top: 15px;
          }

          .outer28 {
               width: 90px;
               height: 78px;
               margin: 69px 0 0 74px;
          }

          .outer29 {
               height: 34px;
               background: $colorMain;
               width: 90px;
               justify-content: center;
               align-items: center;
               cursor: pointer;
          }

          .word152 {
               color: rgba(255, 255, 255, 1);
               font-size: 14px;
          }

          .word153 {
               width: 90px;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               margin-top: 30px;
               text-align: center;
               cursor: pointer;
          }

          .mod32 {
               position: absolute;
               left: 240px;
               top: 60px;
               width: 1px;
               height: 228px;
               background: #f7f7f7f7;
          }

          .mod33 {
               position: absolute;
               left: 480px;
               top: 60px;
               width: 1px;
               height: 228px;
               background: #f7f7f7f7;
          }
     }
}
</style>