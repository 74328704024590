<template>
  <div class="atoJ_con">
    <el-dialog v-model="visibleFlag" width="790px" lock-sroll="false">
      <div class="mod1 flex-col">
        <div class="outer1 flex-col">
          <div class="outer2 flex-col">
            <div class="main1 flex-row">
              <div class="flex_row_start_center">
                <span class="txt1">添加至我的项目</span>
                <span class="cr_p" @click="createProject">+&nbsp;创建项目</span>
              </div>
              <div></div>
            </div>
          </div>
          <div class="outer3">
            <el-scrollbar v-if="projList.length > 0">
              <div class="inner1 flex_row_between_center">
                <div :class="['layer3', 'flex-col', proJIdx == index ? 'layer2' : '']" v-for="(item, index) in projList"
                  :key="index" @click="choProJ(index)">
                  <div class="box5 flex-col">
                    <span class="txt3">{{ item.projectName }}</span>
                    <span class="txt4">{{ item.startTime }}~{{ item.endTime }}</span>
                    <span class="paragraph1">{{ item.description }}</span>
                  </div>
                </div>
              </div>
            </el-scrollbar>
            <div class="empty flex_column_center_center" v-else>
              <img class="empty_img" src="@/assets/new_empty.png" />
              <p class="empty_p">暂无数据</p>
            </div>
          </div>
          <div class="outer4 flex-row">
            <button class="outer5 flex-col" @click="visibleFlag = false">
              <span class="txt6">取消</span>
            </button>
            <button class="outer6 flex-col" @click="addToProject">
              <span class="info4">确定</span>
            </button>
          </div>
        </div>
      </div>
    </el-dialog>

    <projectModel ref="ProjectModel" @refreshProject="refreshProject"></projectModel>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, reactive } from "vue";
import { useStore } from 'vuex';
import { ElMessage } from "element-plus";
import SldCommonEmpty from "@/components/SldCommonEmpty";
import projectModel from './ProjectModel'
export default {
  components: {
    SldCommonEmpty,
    projectModel
  },
  setup() {
    const store = useStore();
    const visibleFlag = ref(false);
    const { proxy } = getCurrentInstance();
    const projList = ref([]);
    const proJIdx = ref(0);
    const chosenProJ = ref({});
    const proInfo = reactive({
      goodsNum: 0,
      productId: "",
    });
    let param = {
      current: 1,
      pageSize: 20,
    };
    const getProJ = () => {
      proxy.$get("v3/member/front/project/list", param).then((res) => {
        if (res.state == 200) {
          projList.value = res.data.list;
          chosenProJ.value = projList.value[0];
        }
      });
    };

    const refreshProject = () => {
      proxy.$refs.ProjectModel.visibleFlag = false;

      getProJ()
    }

    const choProJ = (index) => {
      proJIdx.value = index;
      chosenProJ.value = projList.value[index];
    };

    const addToProject = () => { //加入我的项目
      if (projList.value.length == 0) {
        visibleFlag.value = false
        return;
      }
      let param = {
        productInfos: proInfo.productIds,
        projectId: chosenProJ.value.projectId,
      };
      proxy.$get("v3/goods/front/goods/joinMyProject", param).then((res) => {
        if (res.state == 200) {
          ElMessage.success(res.msg);
          visibleFlag.value = false;
        } else {
          ElMessage(res.msg);
        }
      });
    };


    const createProject = () => {
      proxy.$refs.ProjectModel.initData();
      proxy.$refs.ProjectModel.visibleFlag = true;
      proxy.$refs.ProjectModel.isEdit = false;
    };

    onMounted(() => {

      if (store.state.memberInfo) {
        getProJ();
      }
    });

    return {
      visibleFlag,
      projList,
      proJIdx,
      choProJ,
      proInfo,
      addToProject,
      createProject,
      refreshProject
    };
  },
};
</script>

<style lang="scss">
.atoJ_con {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.page {
  z-index: 1;
  position: relative;
  width: 1920px;
  height: 1895px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
}

.cr_p {
  color: $colorMain;
  font-size: 12px;
  margin-left: 12px;
  margin-top: 2px;
  cursor: pointer;
}

// .layer1 {
//   z-index: 2;
//   height: 1895px;
//   background-color: rgba(0, 0, 0, 0.4);
//   width: 1920px;
//   justify-content: flex-start;
//   align-items: flex-end;
//   padding: 544px 561px 0 0;
// }

.mod1 {
  z-index: 4;
  height: 452px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  width: 790px;
  justify-content: flex-start;

  .outer1 {
    z-index: auto;
    width: 790px;
    height: 434px;

    .outer2 {
      z-index: 5;
      height: 46px;
      border-radius: 4px 4px 0 0;
      background-color: rgba(248, 248, 248, 1);
      width: 790px;
      justify-content: center;
      align-items: center;

      .main1 {
        z-index: auto;
        width: 749px;
        height: 16px;
        justify-content: space-between;

        .txt1 {
          z-index: 36;
          /* height: 14px; */
          display: block;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 15px;
          letter-spacing: -0.33764705061912537px;
          font-family: SourceHanSansCN-Medium;
          white-space: nowrap;
          text-align: center;
          margin-top: 1px;
        }
      }
    }

    // .label1 {
    //   z-index: 37;
    //   width: 16px;
    //   height: 16px;
    // }

    .outer3 {
      width: 750px;
      height: 314px;
      flex-wrap: wrap;
      margin: 20px 0 0 20px;

      .inner1 {
        flex-wrap: wrap;
      }

      .layer2 {
        border: 1px solid $colorMain !important;
        background-color: rgba(247, 249, 255, 1) !important;
        position: relative;

        &::before {
          box-sizing: content-box;
          width: 0;
          height: 0;
          position: absolute;
          bottom: 0;
          right: 0;
          padding-bottom: 1px;
          padding-left: 0px;
          padding-right: 1px;
          padding-top: 0px;
          border-bottom: 14px solid $colorMain;
          border-top: 13px solid transparent;
          border-left: 13px solid transparent;
          border-right: 14px solid $colorMain;
          content: "\e6ff";
          font-family: "iconfont" !important;
          color: #fff;
        }
      }

      .layer3 {
        height: 152px;
        border: 1px solid rgba(151, 151, 151, 1);
        background-color: rgba(255, 255, 255, 1);
        margin-bottom: 10px;
        width: 370px;
        justify-content: center;
        align-items: flex-start;
        padding-left: 14px;
        cursor: pointer;

        .box5 {
          z-index: auto;
          width: 334px;
          height: 114px;
        }

        .txt3 {
          display: block;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          letter-spacing: -0.38588234782218933px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
        }

        .txt4 {
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 12px;
          letter-spacing: -0.2894117534160614px;
          font-family: SourceHanSansCN-Regular;
          white-space: nowrap;
          margin-top: 10px;
        }

        .paragraph1 {
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: -0.33764705061912537px;
          font-family: SourceHanSansCN-Regular;
          line-height: 22px;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          height: 66px;
        }
      }

      .empty {
        height: 300px;

        .empty_img {
          width: 130px;
          height: 120px;
          margin-bottom: 20px;
        }

        .empty_p {
          cursor: default;
          text-align: center;
          color: $colorG;
        }
      }
    }

    .outer4 {
      z-index: auto;
      width: 195px;
      height: 34px;
      justify-content: space-between;
      margin: 20px 0 0 298px;

      .outer5 {
        height: 34px;
        border-radius: 2px;
        border: 1px solid rgba(217, 217, 217, 1);
        background-color: rgba(255, 255, 255, 1);
        width: 90px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .txt6 {
          z-index: 33;
          width: 28px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          letter-spacing: -0.33764705061912537px;
          font-family: PingFangSC-Regular;
          white-space: nowrap;
          line-height: 20px;
          text-align: center;
        }
      }

      .outer6 {
        height: 34px;
        border-radius: 2px;
        background-color: $colorMain;
        width: 90px;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;

        .info4 {
          z-index: 35;
          width: 28px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          letter-spacing: -0.33764705061912537px;
          font-family: PingFangSC-Regular;
          white-space: nowrap;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>