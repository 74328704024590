<template>
  <el-dialog v-model="visibleFlag" width="676px" lock-sroll="false" :destroy-on-close="true">
    <div class="createProject mod1 flex-col">
      <div class="outer1 flex-col">
        <div class="outer2 flex-col">
          <div class="main1 flex-row">
            <span class="txt1">{{isEdit?'编辑项目':'创建项目'}}</span>
            <img class="label1" referrerpolicy="no-referrer" src="@/assets/buy/close.png"
              @click="visibleFlag = false" />
          </div>
        </div>
        <div class="middle">
          <el-form :model="form.data" :rules="rules" ref="ruleForm">
            <el-form-item prop="projectName">
              <div class="mod5_item">
                <span><i class="active">项目名称：</i></span>
                <el-input placeholder="请输入项目名称" v-model="form.data.projectName" autocomplete="off" maxlength="20"
                  show-word-limit clearable />
              </div>
            </el-form-item>
            <el-form-item prop="startTime">
              <div class="mod5_item">
                <span><i class="active">项目周期：</i></span>
                <el-date-picker @change="changeTime" v-model="time" type="daterange" value-format="YYYY-MM-DD"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :disabled-date="disabledDate">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item prop="description">
              <div class="mod5_item flex-start">
                <span>项目描述：</span>
                <el-input type="textarea" :rows="5" autocomplete="off" clearable maxlength="100" placeholder="请输入项目描述"
                  show-word-limit v-model="form.data.description" resize="none" />
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="outer4 flex-row">
          <button class="outer5 flex-col" @click="hideModel">
            <span class="txt6">取消</span>
          </button>
          <button class="outer6 flex-col" @click="createProject">
            <span class="info4">确定</span>
          </button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { reactive, ref, onMounted, getCurrentInstance, toRefs } from "vue";
  import { ElMessage } from 'element-plus';
  export default {
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const visibleFlag = ref(false);
      const isEdit = ref(false);
      const form = reactive({ data: {} });
      const ruleForm = ref(null);
      const time = ref('');
      const projectId = ref('');
      const rules = {
        //校验
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blue" },
          { max: 20, message: "请输入1~20个字符", trigger: "change" },
          {
            pattern: /^[0-9a-zA-Z\u4e00-\u9fa5]+$/,
            message: "请输入正确的项目名称",
            trigger: "change"
          }
        ],
        startTime: [{ required: true, message: "请选择项目周期", trigger: "change" }]
      };

      const dateState = reactive({
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      });

      const changeTime = (e) => {  //切换时间
        if (e) {
          form.data.startTime = e[0];
          form.data.endTime = e[1];
        } else {
          form.data.startTime = '';
          form.data.endTime = '';
        }
      }

      const initData = () => {  //初始化数据
        form.data.projectName = '';
        form.data.startTime = '';
        form.data.endTime = '';
        form.data.description = '';
        time.value = [];
        projectId.value = '';
      }

      const getInfo = (id) => { //获取详情
        projectId.value = id;
        let param = {
          projectId: id
        }
        proxy.$get('v3/member/front/project/detail', param)
          .then(res => {
            if (res.state == 200) {
              form.data.projectName = res.data.projectName;
              form.data.startTime = res.data.startTime;
              form.data.endTime = res.data.endTime;
              form.data.description = res.data.description;
              time.value = [res.data.startTime, res.data.endTime]
            }
          })
      }

      const hideModel = () => {
        initData();
        visibleFlag.value = false;
      }

      const createProject = () => { // 创建/编辑项目
        ruleForm.value.validate((valid) => {
          if (valid) {
            if (form.data.startTime == form.data.endTime) {
              form.data.startTime = form.data.startTime + ' 00:00:00'
              form.data.endTime = form.data.endTime + ' 23:59:59'
            }
            let param = {
              projectName: form.data.projectName,
              startTime: form.data.startTime,
              endTime: form.data.endTime,
              description: form.data.description
            }

            let url = '';
            if (isEdit.value) {
              url = 'v3/member/front/project/update';
              param.projectId = projectId.value;
            } else {
              url = 'v3/member/front/project/add';
            }

            proxy.$post(url, param)
              .then(res => {
                if (res.state == 200) {
                  ElMessage.success(res.msg);
                  emit("refreshProject");
                } else {
                  ElMessage.error(res.msg);
                }
              })
          } else {
            return false;
          }
        })
      }

      onMounted(() => {
        initData();
      });

      return {
        L,
        visibleFlag,
        isEdit,
        form,
        ruleForm,
        time,
        rules,
        changeTime,
        initData,
        getInfo,
        hideModel,
        createProject,
        projectId,
        ...toRefs(dateState)
      };
    },
  };
</script>

<style lang="scss" scoped>
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .page {
    position: relative;
    width: 1920px;
    height: 1895px;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
  }

  .layer1 {
    height: 1895px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 1920px;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 544px 561px 0 0;
  }

  .createProject {
    &.mod1 {
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 1);
      width: 676px;
      height: 420px;
      justify-content: flex-start;
    }

    .outer1 {
      width: 676px;
      height: 420px;
    }

    .outer2 {
      height: 46px;
      border-radius: 4px 4px 0 0;
      background-color: rgba(248, 248, 248, 1);
      width: 676px;
      justify-content: center;
      align-items: center;
    }

    .main1 {
      width: 676px;
      height: 16px;
      justify-content: space-between;
    }

    .txt1 {
      width: 96px;
      height: 14px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 14px;
      letter-spacing: -0.33764705061912537px;
      font-family: SourceHanSansCN-Medium;
      white-space: nowrap;
      line-height: 14px;
      text-align: center;
      margin-top: 1px;
    }

    .label1 {
      position: relative;
      top: 2px;
      width: 12px;
      height: 12px;
      margin-right: 20px;
      cursor: pointer;
    }

    .middle {
      margin-top: 40px;

      .mod5_item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        margin-left: 5px;

        &.flex-start {
          align-items: flex-start;
        }

        span {
          display: inline-block;
          width: 150px;
          height: 34px;
          line-height: 38px;
          font-size: 13px;
          text-align: right;

          i {
            position: relative;
            font-style: normal;

            &.active {
              &:before {
                position: absolute;
                content: '*';
                left: -7px;
                color: $colorMain2;
              }
            }
          }
        }

        .el-input {
          width: 366px;
          height: 34px;
        }

        .onlyread {
          width: 366px;
          height: 34px;
          line-height: 38px;
          font-size: 13px;
          color: $colorF;
        }
      }
    }

    .layer2 {
      border: 1px solid $colorMain  !important;
      background-color: rgba(247, 249, 255, 1) !important;
      position: relative;

      &::before {
        box-sizing: content-box;
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        padding-bottom: 1px;
        padding-left: 0px;
        padding-right: 1px;
        padding-top: 0px;
        border-bottom: 14px solid $colorMain;
        border-top: 13px solid transparent;
        border-left: 13px solid transparent;
        border-right: 14px solid $colorMain;
        content: "\e68d";
        font-family: "iconfont" !important;
        color: #fff;
      }
    }

    .outer4 {
      width: 100%;
      height: 34px;
      margin: 0;
      margin-top: 40px;
      justify-content: center;

      button {
        margin-left: 25px;
        margin-right: 25px;
        cursor: pointer;
      }
    }

    .outer5 {
      height: 34px;
      border-radius: 2px;
      border: 1px solid rgba(217, 217, 217, 1);
      background-color: rgba(255, 255, 255, 1);
      width: 90px;
      justify-content: center;
      align-items: center;
    }

    .txt6 {
      width: 28px;
      height: 20px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      letter-spacing: -0.33764705061912537px;
      font-family: PingFangSC-Regular;
      white-space: nowrap;
      line-height: 20px;
      text-align: center;
    }

    .outer6 {
      height: 34px;
      border-radius: 2px;
      background-color: $colorMain;
      width: 90px;
      justify-content: center;
      align-items: center;
      border: none;
    }

    .info4 {
      width: 28px;
      height: 20px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      letter-spacing: -0.33764705061912537px;
      font-family: PingFangSC-Regular;
      white-space: nowrap;
      line-height: 20px;
      text-align: center;
    }
  }
</style>
<style lang="scss">
  .createProject .middle .el-input__inner {
    width: 366px;
    height: 34px;
  }

  .createProject .middle .el-textarea {
    width: 366px;
  }

  .createProject .middle .el-form-item {
    margin-bottom: 0;
  }

  .createProject .middle .el-form-item__content {
    flex: unset;
    width: 540px;
    margin-left: 10px;
  }

  .createProject .middle .el-form-item__error {
    top: 8px;
    left: unset;
    right: 0;
    transform: translateX(138px);
    width: 150px;
    cursor: default;
  }

  .createProject .middle .el-input .el-input__count .el-input__count-inner {
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .createProject .middle .mod5_item .el-range-separator {
    width: 50px;
    line-height: 28px;
    text-align: center;
  }
</style>