<template>
     <!-- 空数据 -->
     <div class="goods_list empty1">
          <div class="empty1">
               <div class="group112">
                    <div class="mod13">


                         <!-- 列表页-无精准匹配结果时，设计师无负责的业务人员start -->
                         <div class="flex_column_center_center">
                              <img src="@/assets/proEmpty.png" alt="" class="section15">
                         </div>
                         <!-- 列表页-无精准匹配结果时，设计师无负责的业务人员end -->

                         <div class="section13 flex_row_center_center">
                              <span class="iconfont iconjubao icon8"></span>
                              <span class="word49">
                                   暂无符合要求的搜索结果，您可以通过下方联系方式向供应商寻求帮助。
                              </span>
                         </div>


                         <!-- 列表页-无精准匹配结果时，设计师有负责的业务人员start -->
                         <div class="empty_bus flex_column_center_center">
                              <div class="layer231">
                                   <span class="word flex_row_start_center"
                                        v-for="(item,index) in storeList">{{item.storeName}}：<img
                                             src="@/assets/tel.png">{{item.servicePhone}}</span>
                              </div>
                         </div>
                         <!-- 列表页-无精准匹配结果时，设计师有负责的业务人员end -->
                    </div>

               </div>
          </div>
     </div>
</template>


<script>
     import { ref, onMounted, getCurrentInstance } from 'vue'
     export default {
          setup() {
               const storeList = ref([])
               const { proxy } = getCurrentInstance()
               const getStoreList = () => {
                    proxy.$get('v3/seller/front/store/storeList').then(res => {
                         storeList.value = res.data
                    })
               }
               onMounted(() => {

               })

               return {
                    storeList,
                    getStoreList
               }
          }
     }
</script>


<style lang="scss">
     .group112 {
          background-color: #fff;
          margin-top: 0px;
          justify-content: flex-start;
          padding: 15px;
          position: relative;

          .mod13 {}

          .section13 {
               padding: 15px 0;
          }

          .icon8 {
               width: 16px;
               height: 16px;
               color: $colorMain;
               margin-right: 10px;
               margin-left: 20px;
          }

          .word49 {
               width: 448px;
               height: 14px;
               display: block;

               color: rgba(102, 102, 102, 1);
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               line-height: 14px;
               text-align: left;
               margin-top: 1px;
          }

          .section15 {
               width: 103px;
               height: 91px;
               margin-top: 43px;
               margin-bottom: 20px;
          }

          .word50 {
               width: 114px;
               height: 16px;
               display: block;

               color: $colorMain;
               font-size: 16px;
               text-decoration: underline;
               font-family: SourceHanSansCN-Regular;
               white-space: nowrap;
               line-height: 16px;
               margin-bottom: 20px;
          }

          .mod14 {
               height: 56px;

               width: 56px;
               justify-content: center;
               align-items: center;
               position: absolute;
               left: 580px;
               top: 105px;
          }

          .label13 {
               width: 23px;
               height: 20px;
          }
     }

     .empty_bus {
          padding-bottom: 50px;

          .layer231 {
               display: flex;
               z-index: auto;
               width: 954px;
               justify-content: flex-start;
               flex-wrap: wrap;

               img {
                    width: 17px;
                    height: 20px;
                    margin-right: 3px
               }

               .word {

                    color: rgba(102, 102, 102, 1);
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 16px;
                    text-align: left;
                    height: 50px;
                    border: 1px solid #EEEEEE;
                    line-height: 50px;
                    padding: 0 20px;
                    margin-left: 20px;
                    margin-bottom: 20px;
               }
          }

          .layer241 {
               z-index: auto;
               width: 938px;
               justify-content: space-between;
               margin: 40px 0 0 117px;
               display: flex;

               img {
                    width: 17px;
                    height: 20px;
                    margin-right: 3px
               }

               .word {

                    color: rgba(102, 102, 102, 1);
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    white-space: nowrap;
                    line-height: 16px;
                    text-align: left;
                    height: 50px;
                    border: 1px solid #EEEEEE;
                    line-height: 50px;
                    padding: 0 20px;
               }
          }
     }
</style>