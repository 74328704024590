<template>
    <div>
        <div class="goods_list_cate3"
            v-if="(firstLoading ? skelelonData.length : (attributeList.length || seriesList.length))">
            <div class="cate_con" v-if="firstLoading ? skelelonData.length : attributeList.length"
                :style="seriesPage ? 'padding:20px' : ''">

                <div class="flex_row_between_start">
                    <div class="sel_param flex_row_start_start" v-if="attributeList.length">
                        <div class="param_item" style="color: #285FDE;">已选参数：</div>
                        <div class="para_list_item flex_row_start_center" v-if="chosenAttrList.length">
                            <div class="param_item flex_row_center_center" v-for="(item, index) in chosenAttrList"
                                :key="index" @click="attrKick(item)">
                                <div class="sel_border flex_row_center_center">
                                    <img src="../assets/goods/sel_b.png" alt="">
                                </div>
                                <div class="sel_offset">{{ item.attrName }}-{{ item.attrVal }}{{ item.basicUnit }}</div>
                            </div>
                            <div class="clear_param" @click="clearAttr">清除筛选</div>
                        </div>
                    </div>
                    <div>
                        <slot></slot>
                    </div>
                </div>



                <!-- 可选参数start -->
                <div
                    :class="{ param_list: true, flex_row_start_center: true, marG: true, skelelon_loading: firstLoading }">
                    <el-scrollbar>
                        <div style="display: flex;">
                            <template v-for="(item, index) in (firstLoading ? skelelonData : attributeList)"
                                :key="index">
                                <div class="param_class" v-show="item.afterSel">
                                    <div class="p_title flex_row_between_center">
                                        <div class="p_title_o" :title="item.attributeName">{{ item.attributeName }}
                                        </div>
                                        <!-- <div class="p_right" v-if="item.type==2" @click="changeMethod(item)">
                                        <img src="../assets/goods/change_logo.png" alt="">
                                        <span>{{item.inputMethod==2?'直标法':'文字法'}}</span>
                                    </div> -->
                                    </div>
                                    <div class="p_sub_list">
                                        <div class="list_top flex_row_between_center">
                                            <input type="text" class="top_input" @input="searchQuery($event, item)"
                                                v-model="item.searchValue">
                                            <i class="el-icon-search flex_row_end_center"></i>
                                        </div>
                                        <div class="list_bottom"
                                            :style="{ height: item.supportRangeScreen == 1 ? '158px' : '184px' }">
                                            <el-scrollbar>
                                                <!-- <template v-if="item.inputMethod==2"> -->
                                                <div :class="{ param_list_item: true, sel_now: item.sel && computedAttr(item, item1), attrDisabled: !item1.available }"
                                                    v-for="(item1, index1) in item.attributeValueList" :key="index1"
                                                    @click="emitChoseAttr(item1, index1, item, index)">
                                                    <div v-show="item1.attributeValue" :title="item1.attributeValue">
                                                        {{ item1.attributeValue }}{{ item1.basicUnit }}
                                                    </div>
                                                </div>
                                                <!-- </template> -->
                                                <!-- <template v-else>
                                                <div :class="{param_list_item:true,sel_now:item.sel.indexOf(index1)>-1}"
                                                    v-for="(item1,index1) in item.attributeValueList" :key="index1"
                                                    @click="emitChoseAttr(item1,index1,item,index)">
                                                    {{item1.attributeValueAlias}}
                                                </div>
                                            </template> -->

                                            </el-scrollbar>
                                        </div>
                                        <div class="list_abso flex_row_start_center"
                                            v-if="item.supportRangeScreen == 1">
                                            <!-- <template v-if="item.inputMethod==2"> -->
                                            <div class="la_item flex_row_start_center">
                                                <span class="filter_i_con1" :class="{ no_degree: !item.groupId }">
                                                    <input type="text" class="filter_i" v-model="item.startValue"
                                                        @input="delaySearch('start', item)">
                                                </span>
                                                <div class="filter_degree" v-if="item.groupId">
                                                    <label for="degree1">
                                                        <span>{{ item.selUnit1 && item.selUnit1.basicUnit }}</span>
                                                    </label>
                                                    <input type="checkbox" name="" id="degree1" v-model="check1">
                                                    <div class="degree_list flex_column_center_center">
                                                        <span class="dgr_item" v-for="(unit, unitIdx) in item.unitList"
                                                            @click="filterUnit(item, unit, 1, 'check1')"
                                                            :key="unitIdx">{{ unit.basicUnit }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="line">～</div>
                                            <div class="la_item flex_row_start_center">
                                                <span class="filter_i_con1" :class="{ no_degree: !item.groupId }">
                                                    <input type="text" class="filter_i" v-model="item.endValue"
                                                        @input="delaySearch('end', item)">
                                                </span>
                                                <div class="filter_degree" v-if="item.groupId">
                                                    <label for="degree2">
                                                        <span>{{ item.selUnit2 && item.selUnit2.basicUnit }}</span>
                                                    </label>
                                                    <input type="checkbox" name="" id="degree2" v-model="check2">
                                                    <div class="degree_list flex_column_center_center">
                                                        <span class="dgr_item" v-for="(unit, unitIdx) in item.unitList"
                                                            @click="filterUnit(item, unit, 2, 'check2')"
                                                            :key="unitIdx">{{ unit.basicUnit }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </template> -->
                                            <!-- <template v-else>
                                            <div class="la_item flex_row_start_center">
                                                <span class="filter_i_con">
                                                    <input type="text" class="filter_i_go" v-model="item.startValue"
                                                        @input="unitForSearch($event,'start',item)">
                                                </span>
                                            </div>
                                            <div class="line">～</div>
                                            <div class="la_item flex_row_start_center">
                                                <span class="filter_i_con">
                                                    <input type="text" class="filter_i_go" v-model="item.endValue"
                                                        @input="unitForSearch($event,'end',item)">
                                                </span>
                                            </div>
                                        </template> -->
                                        </div>
                                    </div>
                                    <!-- <div class="list_under flex_row_around_center" v-if="true">
                                    <div class="flex_row_start_center">
                                        <i></i>
                                        <span>符合要求</span>
                                    </div>
                                    <div class="flex_row_start_center">
                                        <i></i>
                                        <span>更高精度</span>
                                    </div>
                                </div> -->
                                </div>
                            </template>
                        </div>
                        <div></div>
                    </el-scrollbar>
                </div>
                <!-- 可选参数end -->
            </div>


            <!-- 产品系列start -->
            <template v-if="(firstLoadingSeries ? skelelonDataSeries.length : seriesList.length)">
                <div class="product_series" v-if="series">
                    <div class="title flex_row_between_center">
                        <span class="title_left">产品系列</span>
                        <div class="flex-row">
                            <div class="sld_goods_num flex_row_center_center">
                                <font color="#121212">共</font><span style="margin: 0 4px;">{{ seriesLen }}</span>
                                <font color="#121212">个</font>
                            </div>
                            <div class="bar flex_row_center_center">
                                <span class="el-icon-arrow-left" @click="recomChange('prev')"></span>
                                <span class="txt21" style="font-size: 13px;">
                                    <font color="#285FDE">{{ recommendIndex + 1 }}</font> / <font>{{ seriesList.length
                                    }}
                                    </font>
                                </span>
                                <span class="el-icon-arrow-right" @click="recomChange('next')"></span>
                            </div>
                        </div>


                    </div>
                    <div class="series_carousel">
                        <!-- :interval="5000"  -->
                        <div v-if="firstLoadingSeries">
                            <div class="item_set_six">
                                <proItem v-for="(item, index) in skelelonDataSeries" :key="index" @contrast="contrast"
                                    :item="item" :categoryId="categoryId" :ref="`proItem${index}`"
                                    :loading="firstLoadingSeries">
                                </proItem>
                            </div>
                        </div>
                        <el-carousel :arrow="'never'" height="206px" :autoplay="false" @change="changeCarousel"
                            ref="carousel" v-else>
                            <el-carousel-item v-for="(series, index) in (seriesList)" :key="index">
                                <div class="item_set_six">
                                    <proItem v-for="(item, index) in series" :key="index" @contrast="contrast"
                                        :item="item" :categoryId="categoryId" :ref="`proItem${index}`"
                                        :loading="firstLoadingSeries">

                                        <template v-slot:checkbox>
                                            <el-checkbox v-model="item.checked" @change="emitContrast($event, item)">
                                                加入对比
                                            </el-checkbox>
                                        </template>

                                    </proItem>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </template>
            <!-- 产品系列end -->

            <loadAnimation v-show="!showLoadingMain && showLoading"></loadAnimation>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, watch, onBeforeMount, nextTick, toRefs, computed } from "vue";
import { useRoute } from "vue-router";
import proItem from '@/components/ProItem'
import loadAnimation from '@/components/loading_animation1'
import addCompare from './comparePanel/compare';
import { useStore } from 'vuex'
import eventEmitter from '@/utils/eventEmitter.js'

export default {
    name: "GoodsListCate3",
    props: ["categoryid", 'series', 'showLoading', 'seriesPage'],
    components: { proItem, loadAnimation },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const isOpen = ref(false);
        const route = useRoute();
        const pid = ref(route.query.pid);
        const params = reactive({
            categoryId: props.categoryid,
        });
        const categoryId = ref(route.query.categoryId || route.query.categoryId1)
        const brandData = reactive({ data: [] })
        const attributeList = ref([])
        const isShowSeries = ref(props.series)
        const chosenAttrList = ref([])
        const seriesList = ref([])
        const skelelonData = ref([])
        const skelelonDataSeries = ref([])
        const firstLoading = ref(true)
        const firstLoadingSeries = ref(true)
        const seriesLen = ref(0)
        const selectorParam = reactive({
            resistanceIds: '',
            accuracyIds: '',
            attributeInfo: '',
            characterInfo: '',
            goodsForeignId: '',
            highPrice: '',
            lowPrice: '',
            keyword: '',
            isHasCode: false,
            keyword: route.query.keyword
        })
        const recommendIndex = ref(0)
        const firstRequest = ref(true)
        const searching = ref(false)
        const computedAttr = (item, item1) => {
            let list = item.sel.split(',')

            return list.includes(item1.valueId.toString())
        }
        const checkbox = reactive({
            check1: false,
            check2: false
        })
        const showLoadingMain = ref(false); //产品列表页加载是否完成
        const showLoading = ref(true); //加载动画是否展示
        const store = useStore()

        //各属性的原本列表，用于模糊搜索的重置使用
        const originList = Object.create(null)


        //获取电阻属性在属性列表的下标，计算属性时时更新
        const resisIndex = computed(() => {
            return attributeList.value.findIndex(i => i.type == 2)
        })


        watch(() => props.categoryid, (nv, ov) => {
            if (nv != ov) {
                categoryId.value = props.categoryid
                params.categoryId = nv;
                firstRequest.value = true;
                firstLoading.value = true
                clearPartSelector()
                getInitData();
                getSeries(params)
            }
        });

        watch(() => route.query.replaceKeyword, (nv, ov) => {
            firstRequest.value = true;
            firstLoading.value = true
            clearPartSelector()
            if (route.query.kId) {
                selectorParam.goodsForeignId = route.query.kId
            } else {
                selectorParam.goodsForeignId = null
            }
            selectorParam.replaceKeyword = route.query.replaceKeyword
            getInitData();
            getSeries(params)
        });

        watch(() => route.query.keyword, () => {
            if (route.query.keyword) {
                selectorParam.keyword = route.query.keyword
                firstRequest.value = true;
                firstLoading.value = true
                clearPartSelector()
                getInitData();
                getSeries(params)
            }
        })

        const changeCarousel = (e) => {
            recommendIndex.value = e
        }

        const recomChange = (type) => {
            proxy.$refs.carousel[type]()
        }


        const clearPartSelector = () => {
            selectorParam.lowPrice = ''
            selectorParam.highPrice = ''
            selectorParam.sort = ''
            selectorParam.isHasCode = false
            selectorParam.characterInfo = ''
            selectorParam.accuracyIds = ''
            selectorParam.attributeInfo = ''
            selectorParam.resistanceIds = ''
            chosenAttrList.value = []
        }

        //初始化数据
        const getInitData = () => {
            Object.keys(selectorParam).forEach(item => {
                if (selectorParam[item]) {
                    params[item] = selectorParam[item]
                } else {
                    delete params[item]
                }
            })

            if (route.query.kId && route.query.kId >= 0) {
                params.goodsForeignId = route.query.kId
            }

            if (route.query.seriesId) {
                params.seriesId = route.query.seriesId
            }

            if (route.query.vid) {
                params.storeId = route.query.vid
            }

            if (!firstRequest.value) {
                showLoading.value = true
            }

            proxy.$get("v3/goods/front/goods/category/screenList", params).then((res) => {
                if (res.state == 200) {
                    if (firstRequest.value) {
                        if (res.data.attributeList) {
                            attributeList.value = res.data.attributeList.filter(x => x.attributeValueList.length)
                            attributeList.value.map((item) => {
                                // item.originList = JSON.parse(JSON.stringify(item.attributeValueList));
                                originList[`origin${item.attributeId}`] = JSON.parse(JSON.stringify(item.attributeValueList));
                                item.attributeValueList = item.attributeValueList
                                item.sel = ''
                                item.searchValue = ''
                                item.afterSel = true
                                if (item.supportRangeScreen == 1) {
                                    item.unitList = []
                                    item.startValue = ''
                                    item.endValue = ''
                                    if (item.groupId) {
                                        getUnitList(item)
                                    }
                                }
                            });
                        } else {
                            attributeList.value = []
                        }
                        nextTick(() => {
                            firstLoading.value = false
                            showLoading.value = false;
                        })
                        firstRequest.value = false
                    } else {
                        if (res.data.attributeList) {
                            let resultList = res.data.attributeList.filter(x => x.attributeValueList.length)
                            attributeList.value.map((item) => {
                                item.searchValue = ''
                                if (item.supportRangeScreen == 1 && (item.startValue || item.endValue)) {
                                    // let target = resultList.find(i => t.type == 2)
                                    // handleUniAttrValueList(item, target.attributeValueList)
                                } else {
                                    let index = resultList.findIndex(v => item.attributeId == v.attributeId)

                                    if (index > -1) {
                                        let res1 = resultList[index].attributeValueList
                                        item.attributeValueList = res1.sort((a, b) => b.available - a.available)
                                        originList[`origin${item.attributeId}`] = JSON.parse(JSON.stringify(item.attributeValueList));
                                        item.afterSel = true
                                    } else {
                                        item.attributeValueList = []
                                        originList[`origin${item.attributeId}`] = [];
                                        item.afterSel = false
                                    }
                                }
                            });
                        } else {
                            attributeList.value = []
                        }
                        nextTick(() => {
                            showLoading.value = false;
                        })
                    }
                } else {
                    firstLoading.value = false
                    showLoading.value = false;
                    attributeList.value = []
                }
            });
        };

        const changeMethod = (item) => {
            if (item.inputMethod == 2) {
                item.inputMethod = 1
            } else {
                item.inputMethod = 2
            }
            item.startValue = ''
            item.endValue = ''
        }

        const handleUniAttrValueList = (item, target) => {
            let { attributeValueList } = item
            let final = attributeValueList.filter(item => {
                let index = target.findIndex(i => i.valueId == item.valueId)
                return index > -1
            })

            // attributeValueList.forEach(item => {
            //     if (item.valueId)
            // })


            attributeList.value[resisIndex.value].attributeValueList = final
        }

        //选择属性
        const emitChoseAttr = (item, index, fItem, fIndex) => {

            if (!item.available) {
                return
            }

            let list = fItem.sel.split(',')
            let pos = chosenAttrList.value.findIndex(i => i.attrValId == item.valueId)
            if (!list.includes(item.valueId.toString())) {
                list.push(item.valueId)
                if (pos < 0) {
                    chosenAttrList.value.push({
                        attrId: fItem.attributeId,
                        attrName: fItem.attributeName,
                        attrVal: item.attributeValue,
                        attrValId: item.valueId,
                        basicUnit: item.basicUnit,
                        type: fItem.type
                    })
                }
            } else {
                list = list.filter(i => i != item.valueId)
                chosenAttrList.value.splice(pos, 1)
            }
            fItem.sel = list.join(',')
            let commonList = chosenAttrList.value
            let resistorList = chosenAttrList.value.filter(item => item.type == 2)
            let accurayList = chosenAttrList.value.filter(item => item.type == 3)
            emit('chosenAttr', commonList, resistorList, accurayList)
            handleAttr(commonList, resistorList, accurayList)
        }

        const handleAttr = (commonList, resistorList, accurayList) => {
            if (commonList.length) {
                const sorted = groupBy(commonList, (item) => [item.attrId]);
                let attrList = sorted.map(item => {
                    let list = item.map(i => i.attrValId).join('-')
                    return list
                })
                selectorParam.attributeInfo = attrList.join(',')
            } else {
                selectorParam.attributeInfo = ''
            }

            if (accurayList.length) {
                let list = accurayList.map(i => i.attrValId).join(',')
                selectorParam.accuracyIds = list
            } else {
                selectorParam.accuracyIds = ''
            }

            if (resistorList.length) {
                let list = resistorList.map(i => i.attrValId).join(',')
                selectorParam.resistanceIds = list
            } else {
                selectorParam.resistanceIds = ''
            }
            getSeries(params)
            getInitData()

        }

        //筛选分组
        const groupBy = (arr, func) => {
            const groups = {};
            arr.forEach((o) => { //注意这里必须是forEach 大写
                const group = JSON.stringify(func(o));
                groups[group] = groups[group] || [];
                groups[group].push(o);
            });
            return Object.keys(groups).map(function (group) {
                return groups[group];
            });
        }

        //剔除属性
        const attrKick = (chosen) => {
            let posAttr = chosenAttrList.value.findIndex(i => i.attrValId == chosen.attrValId)
            let posAttrList = attributeList.value.findIndex(i => i.attributeId == chosen.attrId)
            if (posAttr >= 0) {
                chosenAttrList.value.splice(posAttr, 1)
                let list = attributeList.value[posAttrList].sel.split(',')
                let idx = attributeList.value[posAttrList].attributeValueList.findIndex(i => i.valueId == chosen.attrValId)
                list = list.filter(i => i != attributeList.value[posAttrList].attributeValueList[idx].valueId)
                attributeList.value[posAttrList].sel = list.join(',')
            }

            let commonList = chosenAttrList.value
            let resistorList = chosenAttrList.value.filter(item => item.type == 2)
            let accurayList = chosenAttrList.value.filter(item => item.type == 3)

            emit('chosenAttr', commonList, resistorList, accurayList)
            handleAttr(commonList, resistorList, accurayList)

        }

        //已选属性全部清除
        const clearAttr = () => {
            chosenAttrList.value = []
            attributeList.value.map(item => {
                item.sel = ''
            })
            emit('chosenAttr', [], [], [])
            firstRequest.value = true
            handleAttr([], [], [])
        }

        //属性的模糊搜索
        const searchQuery = (e, item) => {
            searching.value = true
            let qs = e.target.value
            let reg = new RegExp(qs, 'i');
            let list = originList[`origin${item.attributeId}`]
            let arr = [];
            for (let i = 0; i < list.length; i++) {
                if (reg.test(`${list[i].attributeValue}${list[i].basicUnit}`)) {
                    arr.push(list[i]);
                }
            }
            let orAttr = chosenAttrList.value.findIndex(e => e.attributeId == item.attributeId)
            if (orAttr > 0) {
                item.sel = arr.findIndex(i => i == chosenAttrList.value[orAttr].attributeValue)
            }
            item.attributeValueList = arr
        }
        //获取系列
        const getSeries = (params) => {
            let param = {
                pageSize: 30,
                categoryIds: params.categoryId
            }

            firstLoadingSeries.value = true

            if (route.query.vid) {
                param.storeId = route.query.vid
            }

            recommendIndex.value = 0


            if (route.query.kId && route.query.kId >= 0) {
                param.goodsForeignId = route.query.kId
            }

            Object.keys(selectorParam).forEach(item => {

                if (item == 'categoryId') {
                    param.categoryIds = selectorParam[item]
                } else if (selectorParam[item]) {
                    param[item] = selectorParam[item]
                }
            })

            proxy.$get('v3/goods/front/goods/seriesList', param).then(res => {
                if (res.state == 200) {
                    firstLoadingSeries.value = false
                    let arrList = res.data.list.map(item => {
                        const list = store.state.contrastList
                        let isExist = list.findIndex(i => i.seriesId == item.seriesId)



                        return {
                            checked: isExist > -1,
                            ...item
                        }
                    })
                    seriesLen.value = 0
                    seriesList.value = (function () {
                        var a_len = arrList.length;
                        var result = [];
                        for (var i = 0; i < a_len; i += 6) {
                            result.push(arrList.slice(i, i + 6));
                            seriesLen.value += arrList.slice(i, i + 6).length

                        }
                        return result;
                    })();
                    proxy.$refs.carousel && proxy.$refs.carousel.setActiveItem(0)

                } else {
                    firstLoadingSeries.value = false
                    seriesList.value = []
                }
            })
        }

        const contrast = (obj) => {
            emit('contrast', obj)
        }



        const filterUnit = (attr, unit, index, chk) => {
            attr[`selUnit${index}`] = unit
            checkbox[chk] = false

            delaySearch('', attr)
        }

        const getUnitList = (item) => {
            if (item.unitList && item.unitList.length) {
                return
            }
            proxy.$get('v3/goods/front/goods/category/unitList', { groupId: item.groupId }).then(res => {
                if (res.state == 200) {
                    item.unitList = res.data
                    let list = item.unitList[0]
                    item.selUnit1 = item.selUnit2 = {
                        unitId: list.unitId,
                        basicUnit: list.basicUnit
                    }
                }
            })
        }


        const delaySearch = (type, item) => {
            setTimeout(() => {
                unitForSearch(type, item)
            }, 500)
        }

        const emitContrast = (e, item) => {
            let paramsData = {
                seriesImage: item.seriesImage,
                seriesId: item.seriesId,
                seriesName: item.seriesName,
                type: 'series'
            };

            addCompare(paramsData, (res) => {
                if (!res.state) item.checked = false
            })
        }

        let tmp = []
        const unitForSearch = (type, item) => {
            let param = {
                attributeId: item.attributeId,
            }
            if (item.startValue.trim()) {
                param.startValue = item.startValue
            }

            if (item.selUnit1 && item.selUnit1.unitId) {
                param.startUnitId = item.selUnit1 && item.selUnit1.unitId

            }

            if (item.endValue.trim()) {
                param.endValue = item.endValue
            }

            if (item.selUnit2 && item.selUnit2.unitId) {
                param.endUnitId = item.selUnit2 && item.selUnit2.unitId

            }

            if (!item.startValue.trim() && !item.endValue.trim()) {
                item.attributeValueList = originList[`origin${item.attributeId}`]

                return
            }
            let originList1 = originList[`origin${item.attributeId}`]

            tmp = []
            proxy.$get('v3/goods/front/goods/category/attributeValueList', param).then(res => {
                if (res && res.state == 200) {
                    tmp = res.data.map(obj => {
                        let index = originList1.findIndex(i => i.valueId == obj.valueId)
                        if (index > -1) {
                            obj.available = originList1[index].available
                        } else {
                            obj.attributeValue = ''
                        }
                        return obj
                    }).sort((a, b) => b.available - a.available)
                }
            }).then(() => {
                item.attributeValueList = tmp
            })


        }

        onMounted(() => {
            params.categoryId = route.query.categoryId || route.query.categoryId1

            if (route.query.replaceKeyword) {
                selectorParam.replaceKeyword = route.query.replaceKeyword
            }

            getInitData();
            getSeries(params)


            //引入 事件订阅和执行，监听对比框删除对比项时的动作，使系列的checked置位false
            eventEmitter.on('contraDel', (type, obj) => {
                seriesList.value.map(lv1 => {
                    lv1.map(lv2 => {
                        if (type == 'spec' && (lv2.seriesId == obj.seriesId)) {
                            lv2.checked = false
                        } else if (type == 'all') {
                            lv2.checked = false
                        }
                    })
                })
            })

        });

        onBeforeMount(() => {
            for (let i = 0; i <= 7; i++) {
                skelelonData.value.push({
                    attributeName: '',
                    attributeValueList: [{}, {}],
                    afterSel: true
                })
            }

            for (let i = 0; i < 6; i++) {
                skelelonDataSeries.value.push({
                    seriesImage: '',
                    seriesAttributeList: [{}, {}, {}, {}]
                })
            }
        })

        return {
            brandData,
            L,
            emitChoseAttr,
            isOpen,
            pid,
            isShowSeries,
            emitChoseAttr,
            chosenAttrList,
            attrKick,
            clearAttr,
            searchQuery,
            attributeList,
            contrast,
            seriesList,
            categoryId,
            changeMethod,
            getUnitList,
            filterUnit,
            unitForSearch,
            firstLoading,
            skelelonData,
            skelelonDataSeries,
            firstLoadingSeries,
            selectorParam,
            getInitData,
            computedAttr,
            firstRequest,
            searching,
            showLoadingMain,
            showLoading,
            getSeries,
            seriesLen,
            changeCarousel,
            recommendIndex,
            recomChange,
            clearPartSelector,
            ...toRefs(checkbox),
            delaySearch,
            emitContrast
        };
    },
};
</script>

<style lang="scss" scoped>
.cate_con {
    padding: 20px 0;
    padding-bottom: 15px;
}

.bar {
    margin-left: 10px;

    .el-icon-arrow-left,
    .el-icon-arrow-right {
        color: #333;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        margin-top: 1px;

        &:hover {
            color: #285FDE;
        }
    }
}


.sel_param {
    flex-wrap: wrap;

    .sel_border {
        height: 15px;
        width: 15px;

        img {
            height: 15px;
            width: 15px;
        }
    }

    .param_item {
        margin-right: 10px;
        cursor: default;
        margin-bottom: 10px;
        font-size: $fontE;
    }

    .para_list_item {
        flex-wrap: wrap;
        flex: 1;
    }

    .sel_offset {
        font-size: 12px;
        margin-left: 5px;
        color: #121212;
    }

    .clear_param {
        color: $colorMain;
        font-size: 12px;
        border-bottom: 1px solid $colorMain;
        margin-left: 10px;
        cursor: pointer;
        margin-bottom: 10px;
    }
}

.param_list {

    width: 100%;

    &.marG {
        margin-top: 15px;
    }

    .p_title {
        color: $colorG;
        font-size: 12px;

        .p_title_o {
            /* max-width: 90px; */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            color: #121212;
            font-size: 15px;
        }

        .p_right {
            cursor: pointer;

            img {
                width: 14px;
                height: 10px;
            }

            span {
                color: #285FDE;
            }
        }
    }

    .param_class {
        min-width: 140px;
        max-width: 140px;
        margin-right: 10px;
        position: relative;
        margin-bottom: 20px;

        .list_under {
            margin-top: 10px;

            span {
                font-size: 10px;
                transform: scale(0.9);
                margin-left: 3px;
            }

            i {
                width: 8px;
                height: 8px;
                background-color: $colorMain;
            }
        }
    }

    .p_sub_list {
        margin-top: 10px;
        border: 1px solid #E2E2E2;
        width: 100%;
        position: relative;

        .list_top {
            padding: 6px 4px;
            display: flex;

            .top_input {
                width: 110px;
                outline: none;
                border: none;
            }

            .el-icon-search {
                font-size: 14px;
                color: #999999;
            }
        }

        .list_bottom {
            background: #F2F2F2;
            height: 184px;
            border-top: 1px solid #E2E2E2;

            .param_list_item {
                color: #333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                div {
                    word-break: break-word;
                    width: 138px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 6px 5px;

                }

                &.sel_now {
                    background: $colorMain;
                    color: #fff;
                    border-bottom: 1px dashed #f9f9f980;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    &:hover {
                        color: #fff !important;
                    }
                }

                &.attrDisabled {
                    opacity: 0.7;
                    color: #999;

                    &:hover {
                        color: #999;
                    }
                }

                &:hover {
                    color: $colorMain;
                }
            }
        }

        .list_abso {

            background-color: #fff;
            height: 26px;
            border-top: 1px solid #E2E2E2;
            border-right: 1px solid #E2E2E2;
            width: 139px;

            .la_item {
                height: 100%;
                border-right: 1px solid #E2E2E2;
                position: relative;

                &:last-child {
                    border-left: 1px solid #E2E2E2;
                    border-right: none;
                }

                .filter_i {
                    width: 26px;
                    height: 20px;
                    border: none;
                    outline: none;
                    text-align: center;
                }

                .filter_i_go {
                    width: 57px;
                    height: 20px;
                    border: none;
                    outline: none;
                    text-align: center;
                }

                .filter_i_con {
                    /* border-right: 1px solid #E2E2E2; */
                    height: 100%;
                    display: flex;
                    align-items: center;

                }

                .filter_i_con1 {
                    border-right: 1px solid #E2E2E2;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }


                .no_degree {
                    border-right: none;

                    .filter_i {
                        width: 52px;

                    }
                }

                .filter_degree {
                    width: 25px;
                    text-align: center;
                    height: 26px;
                    line-height: 26px;
                    cursor: pointer;

                    /* &:hover {
                            .degree_list {
                                display: flex;
                            }
                        } */

                    #degree1,
                    #degree2 {
                        display: none;

                        &:checked+.degree_list {
                            display: flex;
                        }
                    }

                }

                .degree_list {
                    display: none;
                    position: absolute;
                    bottom: 26px;
                    right: -1px;
                    background: #fff;
                    width: 27px;
                    border: 1px solid #E2E2E2;
                    border-bottom: none;

                    .dgr_item {
                        background: #fff;
                        line-height: 20px;
                        cursor: pointer;
                    }
                }


            }

            .line {
                width: 32px;
                text-align: center;
            }

        }
    }

    &.skelelon_loading {
        .p_title_o {
            width: 100px;
            height: 14px;
            background: $colorSkeleton;
        }
    }

}

.product_series {

    border: 1px solid #DBDBDB;

    .title {

        background: #F2F5FD;
        padding-left: 25px;
        padding-right: 20px;
        height: 42px;

        .title_left {
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 600;
            color: #333333;
            line-height: 42px;
        }

        .sld_goods_num {
            line-height: 36px;
            margin-right: 10px;

            span {
                color: $colorMain;
                font-weight: bold;
                margin: 0 2px;
                font-size: 20px;
                font-family: SourceHanSansCN-Regular;
                line-height: 0;
            }
        }
    }

    .series_carousel {
        padding: 22px;

        .item_set_six {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

        }
    }
}

.el-carousel__container {
    height: 210px;
}

.goods_list_cate3 {
    position: relative;
    color: #606060;
    font-size: 12px;
    font-family: MicrosoftYaHei !important;
    background: #fff;
    margin-bottom: 15px;

    .loaders {
        width: auto;
        height: auto;
        position: absolute;
        top: 55px;
        left: 50%;
        right: unset;
        bottom: unset;
    }
}
</style>